import { getApiEndpoint } from '~/api/auth';
import type { ActionBlock, PayItem, Project, Service } from '~/api/types.ts';
import { authFetch } from '~/api/utils';

export const generateProjectApi = async (prompt: string, level: string, entity: string = 'project') => {
  return await authFetch<Project>(getApiEndpoint() + 'projects/', {
    method: 'POST',
    body: {
      prompt,
      level,
      entity,
    },
  });
};

export const getProjects = async (entity: string = 'project'): Promise<Project[]> => {
  return await authFetch<Project[]>(getApiEndpoint() + 'projects/', {
    method: 'GET',
    params: { entity },
  });
};

export const getProject = async (id: string): Promise<Project> => {
  return await authFetch(getApiEndpoint() + 'projects/' + id, {
    method: 'GET',
  });
};

export const rateProject = async (id: string, rating: number, comment?: string): Promise<Project> => {
  return await authFetch(getApiEndpoint() + 'projects/' + id + '/rate', {
    method: 'POST',
    body: {
      rating,
      rating_comment: comment,
    },
  });
};

export const logView = async (
  event: string,
  projectId: string | null,
  yandexClientId: string | null,
  fingerprint: string | null
): Promise<Project> => {
  return await authFetch(getApiEndpoint() + 'log/', {
    method: 'POST',
    body: {
      event,
      project_id: projectId,
      yandexClientId,
      fingerprint,
    },
  });
};

export const getSimilarProject = async (id: string): Promise<Project[]> => {
  return await authFetch(getApiEndpoint() + 'projects/' + id + '/similar', {
    method: 'GET',
  });
};

export const getOwnProject = async (id: string) => {
  return await authFetch(getApiEndpoint() + 'projects/' + id, {
    method: 'GET',
  });
};

export const copyProjectById = async (id: string): Promise<Project> => {
  return await authFetch(getApiEndpoint() + 'projects/' + id + '/copy', {
    method: 'POST',
  });
};

export type PaymentType = 'ru' | 'en';

export const getPaymentLink = async (
  id: string,
  services: string[],
  paymentType: PaymentType,
  promocode?: string
): Promise<{ payment_link: string; amount: number; items: PayItem[] }> => {
  return await authFetch(getApiEndpoint() + 'projects/' + id + '/payment_link', {
    method: 'POST',
    body: {
      services,
      payment_type: paymentType,
      promocode
    },
  });
};

export const getActionBlock = async (props: { promocode?: string; projectId: number; services: Service[] }) => {
  const url = getApiEndpoint() + `projects/${props.projectId}/cart`;

  return await authFetch<ActionBlock>(url, {
    method: 'GET',
    params: {
      promocode: props.promocode,
      'services[]': props.services,
    },
  });
};